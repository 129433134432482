<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <loader-data-table
      v-if="isLoading"
      :columns="6"
      :rows="25"
      :verification="true"
    />

    <v-card class="px-4">
      <v-data-table
        :headers="headers"
        :items="employees"
        :items-per-page="25"
        class="elevation-0 mx-lg-10"
        mobile-breakpoint="100"
        calculate-widths
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="pt-3 mb-4">
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="search"
                class="ml-4"
                append-icon="mdi-magnify"
                label="Cari"
                color="purple"
                clearable
                outlined
                dense
                @keyup.enter.native="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>

            <v-col
              class="text-right"
              cols="12"
              sm="8"
            >
              <v-btn
                v-if="employeeCreate"
                class="text-capitalize"
                color="green"
                @click="dialogTambah = true"
              >
                <span class="font-common-white-bold">
                  Tambah User
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <!-- <template v-slot:[`item.name`]="{ item }">
        {{ item.fname }} {{ }} {{ item.lname }}
      </template> -->

        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip
            color="purple"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                id="switch-container"
                class="d-inline-flex"
                v-bind="attrs"
                v-on="on"
              >
                <v-switch
                  v-model="item.status"
                  :false-value="2"
                  :true-value="1"
                  :disabled="!employeeEdit"
                  inset
                  color="purple"
                  @change="changeStatus(item.hash)"
                />
              </div>
            </template>
            <span v-if="item.status === 1">Non-Aktifkan</span>
            <span v-if="item.status === 2">Aktifkan</span>
          </v-tooltip>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="purple"
              :total-visible="5"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic;">
              Page {{ page }} of {{ pageCount || 1 }}
            </span>
          </div>
          <v-row class="mt-n16">
            <v-col class="text-right pr-8">
              <span style="font-size: 12px; font-style: italic;">
                {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
              </span>
            </v-col>
          </v-row>
        </template>

        <template
          v-slot:[`item.actions`]="{ item }"
        >
          <div class="d-flex">
            <div
              style="
            width: 36px;
            height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
          "
              @click="employeeEdit ? toEdit(item) : null"
            >
              <v-icon
                style="
              font-size: 16px;
              color: #FFC709;
              "
              >
                fas fa-pencil-alt
              </v-icon>
            </div>

            <div
              class="action-container ml-2"
              style="
            width: 36px;
            height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
          "
              @click="toDetail(item)"
            >
              <v-icon
                class="action-icon"
                style="font-size: 16px"
              >
                fas fa-eye
              </v-icon>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG DETAIL -->
    <v-dialog
      v-model="dialogDetail"
      max-width="600"
    >
      <v-card class="px-4 pt-4 pb-8">
        <v-row>
          <v-col>
            <span style="font-weight: bold">
              Detail User
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              style="cursor: pointer"
              @click="dialogDetail = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <template v-if="selectedItem !== null">
          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Nama Lengkap
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.name }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Divisi
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.pos_divisi }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Peran
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.pos_name }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Telepon
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.phone }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Email Pribadi
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.email_personal }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Email Travikr
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.email }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Tanggal Bergabung
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.tanggal_daftar }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Alamat Lengkap
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ selectedItem.address_complete }}
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>

    <!-- DIALOG TAMBAH -->
    <v-dialog
      v-model="dialogTambah"
      max-width="600"
    >
      <v-card class="px-4 pt-4 pb-8">
        <v-row>
          <v-col>
            <span style="font-weight: bold">
              Tambah User
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              style="cursor: pointer"
              @click="dialogTambah = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Posisi
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-select
              v-model="selectedPosisi"
              :items="posisi"
              placeholder="Pilih Posisi"
              item-text="pos_name"
              item-value="pos_hash"
              color="purple"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Nama Lengkap
          </v-col>

          <v-col
            class="d-flex"
            cols="4"
            sm="4"
            style="flex-direction: column"
          >
            <div class="font-common">
              Nama Depan
            </div>

            <div>
              <v-text-field
                v-model="namaDepan"
                dense
                placeholder="Nama Depan"
                outlined
                color="purple"
              />
            </div>
          </v-col>

          <v-col
            class="d-flex"
            cols="4"
            sm="4"
            style="flex-direction: column"
          >
            <div class="font-common">
              Nama Belakang
            </div>

            <div>
              <v-text-field
                v-model="namaBelakang"
                color="purple"
                dense
                placeholder="Nama Belakang"
                outlined
              />
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Telepon
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-text-field
              v-model="noTelepon"
              color="purple"
              dense
              placeholder="Masukkan Nomor Telepon"
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Email Pribadi
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-text-field
              v-model="emailPribadi"
              color="purple"
              dense
              placeholder="Masukkan Email Pribadi"
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Email Travikr
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-text-field
              v-model="emailTravikr"
              color="purple"
              dense
              placeholder="Masukkan Email Travikr"
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Tanggal Bergabung
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <date-picker
              v-model="tanggalBergabung"
              placeholder="Tanggal Bergabung"
              value-type="format"
              format="YYYY-MM-DD"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Kota/ Kecamatan
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-autocomplete
              v-model="selectedKotaKec"
              :items="kotaKec"
              :loading="false"
              label="Kota / Kecamatan"
              item-text="string"
              item-value="hash"
              color="purple"
              autocomplete="off"
              placeholder="Ketik kota atau kecamatan anda..."
              outlined
              dense
              :search-input.sync="searchKotaKec"
              @change="getDataKodePos"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n3">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Kode Pos
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-select
              v-model="selectedKodePos"
              :items="kodePos"
              placeholder="Pilih Kode Pos"
              item-text="pos_name"
              item-value="pos_hash"
              color="purple"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-n3">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Alamat
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-textarea
              v-model="alamat"
              outlined
              placeholder="Masukkan Alamat"
              color="purple"
              no-resize
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            <v-btn
              class="text-capitalize mx-2 elevation-0 font-common-white-bold"
              style="
              width: 100px;
              height: 46px;
              background: #7A1878;
            "
              @click="submit"
            >
              Simpan
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0 font-common-bold"
              style="
              width: 71px;
              height: 46px;
              background: #F2F2F2;
            "
              @click="dialogTambah = false"
            >
              <span style="color: #CCCCCC">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG EDIT -->
    <v-dialog
      v-model="dialogEdit"
      max-width="600"
    >
      <v-card class="px-4 pt-4 pb-10">
        <v-row>
          <v-col>
            <span style="font-weight: bold">
              Edit User
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              style="cursor: pointer"
              @click="dialogEdit = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Posisi
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-select
              v-model="selectedPosisi"
              :items="posisi"
              placeholder="Pilih Posisi"
              item-text="pos_name"
              item-value="pos_hash"
              color="purple"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Nama Lengkap
          </v-col>

          <v-col
            class="d-flex"
            cols="4"
            sm="4"
            style="flex-direction: column"
          >
            <div class="font-common">
              Nama Depan
            </div>

            <div>
              <v-text-field
                v-model="namaDepan"
                dense
                placeholder="Nama Depan"
                outlined
                color="purple"
              />
            </div>
          </v-col>

          <v-col
            class="d-flex"
            cols="4"
            sm="4"
            style="flex-direction: column"
          >
            <div class="font-common">
              Nama Belakang
            </div>

            <div>
              <v-text-field
                v-model="namaBelakang"
                color="purple"
                dense
                placeholder="Nama Belakang"
                outlined
              />
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Telepon
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-text-field
              v-model="noTelepon"
              color="purple"
              dense
              placeholder="Masukkan Nomor Telepon"
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Email Pribadi
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-text-field
              v-model="emailPribadi"
              color="purple"
              dense
              placeholder="Masukkan Email Pribadi"
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Email Travikr
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-text-field
              v-model="emailTravikr"
              color="purple"
              dense
              placeholder="Masukkan Email Travikr"
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Tanggal Bergabung
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <date-picker
              v-model="tanggalBergabung"
              placeholder="Tanggal Bergabung"
              value-type="format"
              format="YYYY-MM-DD"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Kota/ Kecamatan
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-autocomplete
              v-model="selectedKotaKec"
              :items="kotaKec"
              :loading="false"
              label="Kota / Kecamatan"
              item-text="string"
              item-value="hash"
              color="purple"
              autocomplete="off"
              placeholder="Ketik kota atau kecamatan anda..."
              outlined
              dense
              :search-input.sync="searchKotaKec"
              @change="getDataKodePos"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n3">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Kode Pos
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-select
              v-model="selectedKodePos"
              :items="kodePos"
              placeholder="Pilih Kode Pos"
              item-text="pos_name"
              item-value="pos_hash"
              color="purple"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-n3">
          <v-col
            class="font-common"
            cols="4"
            sm="4"
          >
            Alamat
          </v-col>

          <v-col
            class="font-common"
            cols="8"
            sm="8"
          >
            <v-textarea
              v-model="alamat"
              outlined
              placeholder="Masukkan Alamat"
              color="purple"
              no-resize
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            <v-btn
              class="text-capitalize mx-2 elevation-0 font-common-white-bold"
              style="
              width: 100px;
              height: 46px;
              background: #7A1878;
            "
              @click="submit('UPDATE')"
            >
              Simpan
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0 font-common-bold"
              style="
              width: 71px;
              height: 46px;
              background: #F2F2F2;
            "
              @click="dialogEdit = false"
            >
              <span style="color: #CCCCCC">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      LoaderDataTable: () => import('../../component/LoaderDataTable'),
      'date-picker': DatePicker,
    },
    data: () => ({
      search: '',
      headers: [
        { text: 'Nama Lengkap', align: 'start', value: 'name' },
        { text: 'Divisi', value: 'pos_divisi' },
        { text: 'Peran', value: 'pos_name' },
        { text: 'Domisili', value: 'address_complete', sortable: false },
        { text: 'Status', value: 'status' },
        { text: '', value: 'actions', sortable: false },
      ],
      employees: [],

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      isLoading: false,

      dialogDetail: false,
      dialogTambah: false,
      dialogEdit: false,

      // Posisi:
      posisi: [],
      selectedPosisi: null,

      // Kota/ Kecamatan:
      searchKotaKec: null,
      kotaKec: [],
      selectedKotaKec: null,

      // Kodepos:
      kodePos: [],
      selectedKodePos: null,

      selectedItem: null,

      // Tambah User:
      namaDepan: '',
      namaBelakang: '',
      noTelepon: '',
      emailPribadi: '',
      emailTravikr: '',
      tanggalBergabung: '',
      alamat: '',

      // Edit User:
      userHash: '',

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      employeeCreate () {
        let v = false
        if (this.userMenus.includes('EMPLOYEE_CREATE')) v = true

        return v
      },

      employeeEdit () {
        let v = false
        if (this.userMenus.includes('EMPLOYEE_EDIT')) v = true

        return v
      },
    },

    watch: {
      searchKotaKec (val) {
        if (val === null) return
        if (val.trim().length < 3) return
        if (/[^a-zA-Z]/.test(val)) return
        this.getDataKotaKecamatan(val)
      },
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      this.getPosisi()
    },

    methods: {
      async initialize (page, limit, filter) {
        this.isLoading = true

        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')
        // if (userMenus.includes('EMPLOYEE_CREATE')) {
        //   this.isAllowCreate = true
        // }
        // if (userMenus.includes('EMPLOYEE_EDIT')) {
        //   this.isAllowEdit = true
        // }

        // this.isLoading = true

        const vm = this
        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }
        await axios.post('/v2/t/employee', this.$qs.stringify(requestBody)).then((res) => {
          if (res.data.status === 200) {
            vm.employees = res.data.data.list
            // Pagination:
            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })

        this.isLoading = false
      },

      getPosisi () {
        const vm = this

        const requestBody = {
          page: 1,
          limit: 100,
        }
        axios.post('/v2/t/employee/getRoles', this.$qs.stringify(requestBody)).then((res) => {
          if (res.data.status === 200) {
            vm.posisi = res.data.data.roles
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      changeStatus (hash) {
        const requestBody = new URLSearchParams()
        requestBody.append('emp_hash', hash)

        axios.post('/v1/t/employee/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getDataKotaKecamatan (keywords) {
        const requestBody = {
          q: keywords,
        }

        axios.post('/v1/g/allAdd', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.kotaKec = res.data.data.address
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getDataKodePos (addressHash) {
        const requestBody = {
          addHash: addressHash,
        }

        axios.post('/v1/g/allAddPos', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.kodePos = res.data.data.posList
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      submit (key) {
        let api = '/v2/t/employee/create'

        const requestBody = {
          position_hash: this.selectedPosisi,
          f_name: this.namaDepan,
          l_name: this.namaBelakang,
          personal_mail: this.emailPribadi,
          travikr_mail: this.emailTravikr,
          personal_phone: this.noTelepon,
          add_hash: this.selectedKotaKec,
          add_pos: String(this.selectedKodePos),
          add_string: this.alamat,
          join_date: this.tanggalBergabung,
        }

        if (key === 'UPDATE') {
          api = '/v2/t/employee/update'
          requestBody.emp_hash = this.userHash
        }

        axios.post(api, requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.message)
            this.dialogTambah = false
            this.selectedPosisi = null
            this.namaDepan = ''
            this.namaBelakang = ''
            this.emailPribadi = ''
            this.emailTravikr = ''
            this.noTelepon = ''
            this.selectedKotaKec = null
            this.selectedKodePos = null
            this.alamat = ''
            this.initialize(1, this.itemsPerPage)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toDetail (item) {
        this.selectedItem = item
        this.dialogDetail = true
      },

      toEdit (item) {
        this.kotaKec = []
        this.kodePos = []

        const addr = {}
        addr.string = item.address_complete
        addr.hash = item.address_hash
        this.kotaKec.push(addr)

        const posCode = {}
        posCode.pos_name = item.poscode
        posCode.pos_hash = item.poscode
        this.kodePos.push(posCode)

        this.userHash = item.hash
        this.selectedPosisi = item.pos_hash
        this.namaDepan = item.fname
        this.namaBelakang = item.lname
        this.emailPribadi = item.email_personal
        this.emailTravikr = item.email
        this.noTelepon = item.phone
        this.selectedKotaKec = item.address_hash
        this.selectedKodePos = item.poscode
        this.alamat = item.address_complete
        this.tanggalBergabung = this.formatDate(item.tanggal_daftar)
        this.dialogEdit = true
      },

      formatDate (date) {
        var d = new Date(date)
        var month = '' + (d.getMonth() + 1)
        var day = '' + d.getDate()
        var year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
